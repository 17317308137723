import * as React from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { animated } from 'react-spring';

import { useMutation, useQuery } from '@apollo/client';
import { SOLDPRICE_PROPERTY_QUERY } from '../../../../client/__graphql__/queries';

import { useForm } from '../../../utils/hooks/useForm';

import { Section } from '../../layout/Section';
import { Container } from '../../layout/Container';
import { BackgroundLoad } from '../../layout/BackgroundLoad';

import { Input } from '../../ui/form/Input';
import { FormError } from '../../ui/form/FormError';
import { Heading } from '../../ui/text/Heading';
import { Paragraph } from '../../ui/text/Paragraph';
import { Form, FormRow } from '../../ui/form/Form';
import { ButtonGroup } from '../../ui/button/ButtonGroup';
import { Button } from '../../ui/button/Button';
import { BrokerListItem } from '../../ui/broker/ListItem';

import { Thanks } from '../../layout/Thanks';

import { useFormTransition } from '../../../utils/hooks/useFormTransition';

import { useFadeTransition } from '../../../utils/hooks/useFadeTransition';
import { SOLDPRICE_MUTATION } from '../../../../client/__graphql__/mutations';
import { createGTMEvent } from '../../../utils/tracking/gtm';
import NoMatch from '../404Old';

const Solgtpris: React.FC = () => {
  const params = useParams<{ sguid: string }>();
  const [posted, setPosted] = React.useState(false);

  const { data, loading: propertyLoading } = useQuery(
    SOLDPRICE_PROPERTY_QUERY,
    {
      fetchPolicy: 'network-only',
      variables: {
        input: {
          sguid: params.sguid
        }
      }
    }
  );

  if (propertyLoading) {
    return null;
  }

  if (!data || !data.property) {
    return <NoMatch />;
  }

  const { fadeIn: pageFadeIn } = useFadeTransition({
    immidiate: !propertyLoading && data
  });

  const [lead, { loading, error }] = useMutation(SOLDPRICE_MUTATION, {
    onError: ({ graphQLErrors, networkError }) => {
      console.log(graphQLErrors, networkError);
    },
    onCompleted: ({ lead: { soldprice } }) => {
      if (soldprice && soldprice.success) {
        window.scroll(0, 0);
        setPosted(true);
        createGTMEvent({
          event: 'pmFormSubmission',
          gaCategory: 'RT_SOLGTPRIS',
          gaAction: 'RT_SEND_SOLGTPRIS',
          gaEvent: `RT_SEND_SOLGTPRIS_${params.sguid}`
        });
      }
    }
  });

  const { fields, getFields, handleSubmit, handleChange } = useForm({
    fields: [
      {
        type: 'tel',
        name: 'phone',
        placeholder: 'Eks. 00000000',
        value: '',
        label: 'Telefon *',
        labelAfter: '(Brukes til å kontakte deg)',
        required: true
      }
    ],
    submit: () => {
      const preparedFields: any = getFields(fields);
      lead({
        variables: {
          input: {
            identifier: params.sguid,
            leadType: 'SOLDPRICE',
            pageType: 'OBJEKT',
            phone: preparedFields.phone,
            zip: '0000'
          }
        }
      });
    }
  });

  const { fadeIn, fadeOut } = useFormTransition({
    posted
  });

  return (
    <>
      <Container
        style={{ ...pageFadeIn, minHeight: '100vh' }}
        className="padding-top"
      >
        <FormWrapper style={fadeOut}>
          <Section className="form-section" style={{ paddingBottom: '10px' }}>
            {data?.property?.sold ? (
              <Heading tag="h1" center={true}>
                Ønsker du å vite eiendommens endelige salgssum?
              </Heading>
            ) : (
              <Heading tag="h1" center={true}>
                Ønsker du å vite hva denne eiendommen blir solgt for?
              </Heading>
            )}
          </Section>
          <Form onSubmit={handleSubmit} noValidate>
            {error &&
              error.graphQLErrors.map((error, index) => (
                <FormError key={`error_${index}`} message={error.message} />
              ))}
            {fields.map((item: any, index) => {
              return (
                <FormRow key={`field_${index}`}>
                  <Input
                    type={item.type}
                    name={item.name}
                    label={item.label}
                    labelAfter={item.labelAfter}
                    placeholder={item.placeholder}
                    value={item.value}
                    onChange={handleChange}
                    error={item.error}
                  />
                </FormRow>
              );
            })}
            <Paragraph center={true} className="form-consent-text">
              * Ved å klikke Send aksepterer du at eiendomsmegler kan kontakte
              deg med informasjon om eiendommen, samt spørsmål om salg av egen
              eiendom, med forbehold om at kjøper og selger samtykker til at
              informasjon om salgssum deles.{' '}
              <a href="https://privatmegleren.no/personvern">
                Personvernpolicy
              </a>
            </Paragraph>
            <ButtonGroup position="center" className="space-top-bottom">
              <Button
                type="primary"
                colorTheme="gold"
                disabled={loading}
                loading={loading}
              >
                Send
              </Button>
            </ButtonGroup>
          </Form>
          <Section className="form-section">
            <Heading tag="h3" center={true}>
              Kontakt meg gjerne hvis du har spørsmål:
            </Heading>
            <BrokerList>
              {data?.property?.brokers?.list.map((item: any, index: number) => (
                <BrokerListItem
                  key={`broker_${index}`}
                  name={item?.name}
                  title={item?.title}
                  office={item?.office?.info?.name}
                  image={item?.image?.full}
                  path={`https://privatmegleren.no/megler/${item.path}`}
                />
              ))}
            </BrokerList>
          </Section>
        </FormWrapper>
        <Thanks style={fadeIn}>
          <Section className="form-section">
            <Heading tag="h1" center={true}>
              Takk for din henvendelse
            </Heading>
          </Section>
        </Thanks>
      </Container>
      <BackgroundLoad
        showOnMobile={true}
        opacity={0.35}
        url="https://cdn.reeltime.no/pm_assets/img/bakgrunner/hjort_bakside2.jpg"
      />
    </>
  );
};

export default Solgtpris;

const FormWrapper = styled(animated.div)`
  .notfollowingstandards {
    font-family: 'Proxima-Nova', sans-serif;
  }
`;

const BrokerList = styled.div`
  display: flex;
  flex-flow: column;
`;
